<template>
  <v-main>
    <v-app
      id="dashboard-view"
      style="background-color: rgb(247, 247, 247)"
      light>
      <!-- <core-filter /> -->
      
      

      <core-toolbar @showBusquedaDocumentacion="showBusquedaDocumentacion" />
      
      <core-drawer />
      
      <!---
      <h1>Hola mudno</h1>
      -->
      
      <v-fade-transition mode="out-in">
        <div>
          <div class="mx-3">
            <PushNotifications />
            <v-navigation-drawer
                v-model="activarBusqueda"
                fixed
                right
                temporary
                width="21.875rem"
                style="z-index: 400;background-color: rgb(247, 247, 247) !important"
                @input="toggleDialogBusqueda"
              >
              <v-container>
                <v-row>
                  <v-col cols="12" :style="`background-color: #1565C0 !important`" class="pb-6">
                    <v-col cols="12" class="pa-0 d-flex">
                      <v-col cols="1" class="pa-0 d-flex align-center">
                        <v-icon
                          color="white"
                          style="cursor: pointer"
                          @click="cerrarBusqueda()"
                          size="28"
                          >mdi-chevron-left</v-icon
                        >
                      </v-col>
                      <v-col cols="11" class="pa-0 pl-2">
                        <p class="mb-1 white--text text-h6 font-weight-medium">Buscar documentación</p>
                        <v-text-field
                          v-model="busqueda"
                          filled
                          rounded
                          dense
                          dark
                          placeholder="Introduzca su busqueda..."
                          prepend-inner-icon="mdi-magnify"
                          clearable
                          hide-details
                          @input="realizarBusqueda()"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                  </v-col>
                  <v-progress-linear
                    v-show="searching"
                    indeterminate
                    color="primary"
                    height="3"
                  ></v-progress-linear>
                </v-row>
                <v-row>
                  <v-slide-y-transition group>
                  <template v-if="busquedaItem.resultados.length > 0">
                    <v-col cols="6" class="pb-2" key="text-result">
                      <p 
                        v-if="!searching"
                        class="mb-0 text-h6"
                      >
                        {{busquedaItem.total}} Resultados
                      </p>
                    </v-col>
                    <v-col cols="12" class="pt-0" v-for="resultado,idx in busquedaItem.resultados" :key="`resultado-${idx}`">
                      <v-card
                        v-if="!searching"
                        elevation="2"
                        class="result-card"
                        :class="getBorderColor(resultado.type)"
                        :href="resultado.url"
                        target="_blank"
                        :key="`card-${idx}`"
                      >
                        <v-col cols="12" class="pb-0">
                          <p class="mb-0 font-weight-bold" style="font-size: 1.25rem !important">{{resultado.name}}</p>
                        </v-col>
                        <v-card-text class="pt-4 pb-0">
                          <p class="mb-0 text-body-2" v-html="resultado.preview_html.content"></p>
                        </v-card-text>
                        <v-card-actions class="mx-4">
                          <v-col cols="12" class="pa-0 d-flex justify-space-between align-end">
                            <div>
                              <v-chip
                                v-for="tag,idx in resultado.tags"
                                class="mr-2 mb-1"
                                small
                                :key="`tag-${idx}`"
                              >
                                {{tag.name}}
                              </v-chip>
                            </div>
                            <div>
                              <v-icon size="20">mdi-open-in-new</v-icon>
                            </div>
                          </v-col>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </template>
                  </v-slide-y-transition>
                  <template v-if="busquedaItem.resultados.length == 0">
                    <v-col cols="12" style="height: 85vh" class="d-flex justify-center align-center">
                      <div class="text-center">
                        <v-icon size="100" color="grey lighten-1">mdi-book-open-variant</v-icon>
                        <p class="font-weight-bold" style="font-size: 1.75rem !important; color: #BDBDBD">
                          {{(busqueda && !tecleando && !searching) ? `No hay resultados para "${busqueda}"` : 'Documentación'}}
                        </p>
                      </div>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </v-navigation-drawer>

            <v-alert
              v-if="!validado"
              border="left"
              color="orange"
              elevation="2"
              colored-border
              class="py-3"
            >
              <v-row align="center">
                <v-icon
                  color="orange"
                  large
                  class="ml-5"
                >
                  {{ solicitado ? 'mdi-email' : 'mdi-alert' }}
                </v-icon>
                <v-col class="grow">
                  <p
                    v-show="!solicitado"
                    class="my-0 py-0 texto-alerta"
                  >
                    Por seguridad es necesario <strong class="texto-alerta">verificar su cuenta.</strong>
                  </p>
                  <p
                    v-show="solicitado"
                    class="my-0 py-0 texto-alerta"
                  >
                    Se ha enviado un email a su correo para <strong class="texto-alerta">verificar su cuenta.</strong>
                  </p>
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    class="orange my-0 py-0"
                    v-show="!solicitado"
                    :loading="loadingSolicitud"
                    :disabled="loadingSolicitud"
                    @click="verificarCuenta()"
                  >
                    Verificar cuenta
                  </v-btn>
                </v-col>
                <v-btn
                  icon
                  color="grey"
                  medium
                  class="mr-1"
                  @click="ocultarVerificar"
                >
                  <v-icon
                    >mdi-close</v-icon
                  >
                </v-btn>
              </v-row>
            </v-alert>
            <v-alert
              v-if="cambiarPassword"
              color="red"
              border="left"
              elevation="2"
              colored-border
              class="py-3"
            >
              <v-row align="center">
                <v-icon
                  color="red"
                  large
                  class="ml-5"
                >
                  mdi-shield-lock
                </v-icon>
                <v-col class="grow">
                  <p
                    class="my-0 py-0 texto-alerta"
                  >
                    Por seguridad es necesario que <strong class="texto-alerta">cambie su contraseña.</strong>
                  </p>
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    class="red my-0 py-0"
                    @click="redirCambioPassword()"
                  >
                    Cambiar contraseña
                  </v-btn>
                </v-col>
                <v-btn
                  icon
                  color="grey"
                  medium
                  class="mr-1"
                  @click="ocultarPassword"
                >
                  <v-icon
                    >mdi-close</v-icon
                  >
                </v-btn>
              </v-row>
            </v-alert>
          </div>


          <router-view />

        </div>      
      </v-fade-transition>
      <br>
      <br>
      <br>
      <br>
      <br>
      <core-footer/>

    </v-app>
  </v-main>
</template>

<script>
import { debounce } from "debounce";
import { mapState } from 'vuex';
import axios from "axios";
import PushNotifications from './PushNotifications.vue';

export default {
	components: { PushNotifications },
  name: 'DashboardView',
  metaInfo () {
    return {
      title: 'ManagrX'
    }
  },
  data(){
    return {
      tecleando: false,
      activarBusqueda: false,
      searching: false,
      busqueda: null,
      busquedaItem: {
        resultados: [],
        total: 0
      },
      solicitado: false,
      loadingSolicitud: false
    }
  },
  created(){
    this.cuentaVerificada();
  },
  computed:{
    ...mapState(['validado','cambiarPassword'])
  },
  methods: {
    toggleDialogBusqueda(value){
      if(value) return;
      this.cerrarBusqueda();
    }, 
    realizarPeticionDebounce: debounce(function () {
      this.tecleando = false;
			this.searchDocumentacion();
		}, 750),
    cerrarBusqueda(){
      this.activarBusqueda = false;
      this.busquedaItem.resultados = [];
      this.busquedaItem.total = 0;
      this.busqueda = null;
    },
    showBusquedaDocumentacion(value){
      this.activarBusqueda = value;
    },
    getBorderColor(type){
      return type == "book"
        ? 'border-left-green'
        : 'border-left-blue';
    },
    realizarBusqueda(){
      this.tecleando = true;
      if(!this.busqueda) {
        this.busquedaItem.resultados = [];
        this.busquedaItem.total = 0;
        return;
      }

      this.realizarPeticionDebounce();
    },
    searchDocumentacion(){
      this.searching = true;
      axios({
        url: "https://documentacion.managrx.com/api/search",
        params: {
          query: this.busqueda.trim()
        },
        headers: {
          Authorization: 'Token gE57kILn2wPo3qwQ0LG5wj3AdstQkUm2:73DDilPWzycGfXXIo2jGzfvYVzXDiGxy'
        }
      })
        .then( ({data: response}) => {
          this.busquedaItem.resultados = response.data.filter(item => item.type != "chapter");
          this.busquedaItem.total = this.busquedaItem.resultados.length;
        })
        .catch( error => {
          console.log(error);
        })
        .finally(() => {
          this.searching = false;
        });
    },
    async ocultarVerificar(){
      await this.$store.dispatch( 'ignoreEmailAlert' );
    },
    async ocultarPassword(){
      await this.$store.dispatch( 'ignoreChangePassword' );
    },
    async cuentaVerificada(){
      if( this.$route.redirectedFrom === '/dashboard/validacion' )
        await this.$store.dispatch( "refreshtoken" );
    },
    verificarCuenta(){
      this.loadingSolicitud = true;
      axios
        .get( '/Usuarios/SolicitarValidacion' )
        .then( response => {
          this.loadingSolicitud = false;
          this.solicitado = true;
        })
        .catch( error => {
          this.loadingSolicitud = false;
          this.solicitado = false;
          console.log( error );
        })
    },
    redirCambioPassword(){
      this.$router.push('/dashboard/seguridad?tab=tab-4')
    }
  }
}
</script>



<style>
/*
button.v-btn.v-btn--flat.v-btn--icon.v-btn--round.v-btn--text.theme--light.v-size--default.span{
  color:rgba(0,0,0,.54) !important;
}
*/
i.v-icon.notranslate.mdi.mdi-chevron-right.theme--light{
  color:rgba(0,0,0,.54) !important;
}
i.v-icon.notranslate.mdi.mdi-chevron-left.theme--light{
  color:rgba(0,0,0,.54) !important;
}

.texto-alerta {
  font-size: 16px !important;
}
</style>
<style scoped>
.border-left-blue {
  border-left: 7px solid #1E88E5;
}

.border-left-green {
  border-left: 7px solid #43A047;
}

.result-card {
  background-color: white;
  transition: 0.3s ease-in-out;
}

.result-card:hover {
  background-color: rgb(247, 247, 247);
  transition: 0.3s ease-in-out;
}

.result-card:hover >>> .v-icon{
  color: #1565C0 !important;
}

.input-busqueda >>> .v-text-field__slot{
   background-color: white !important;
}

.input-busqueda >>> .v-input__slot{
   background-color: white !important;
}
</style>